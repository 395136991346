<template>
  <v-card elevation="0">
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text" v-if="operation === 'update'">Modifier le plan</v-toolbar-title>
      <v-toolbar-title class="white--text" v-if="operation === 'create'">Créer le plan</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="planForm">
        <v-file-input v-model="planModel.base_image" label="Base du plan"></v-file-input>
        <span class="body-2 grey--text text--darken-1">Résolution conseillé 800x600, en image (JPEG ou PNG)</span>
        <v-text-field label="Nom du plan"  :rules="[value => value !== null || 'Champ obligatoire']" v-model="planModel.name"></v-text-field>
        <v-select label="Catégorie" :items="domaines" :rules="[value => value !== null || 'Champ obligatoire']" item-text="name" item-value="id" v-model="planModel.category"></v-select>
        <h4>Site du plan</h4>
        <v-autocomplete label="Site"
                          item-text="name"
                          item-value="id"
                          :rules="[planModel.site !== null || 'Vous devez spécifier un site pour le plan.']"
                          :items="sites" v-model="planModel.site">
          </v-autocomplete>
          <v-radio-group class="ma-0 pa-0" v-model="planModel.site">
            <v-treeview
                :items="sites_tree"
                item-text="name"
                item-key="id"
                item-children="sites"
                dense>
              <template v-slot:append="{item}">
                <v-radio :value="item.id"></v-radio>
              </template>
            </v-treeview>
          </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="postPlan">Créer le plan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PlanForm",
  async mounted() {
    if(this.$store.getters["categories/domaines"].length === 0) {
      await this.$store.dispatch("categories/fetchCategories");
    }
    if(this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
  },
  props: {
    plan: {
      type: Object,
      default: () => {}
    },
    operation: {
      type: String,
      default: "create",
    }
  },
  data() {
    return {
      planModel: this.operation === "update" ? JSON.parse(JSON.stringify(this.plan)) : {
        "name": "",
        "base_image": null,
        "category": null,
        "site": null
      }
    };
  },
  computed: {
    sites_tree() {
      return this.$store.getters["sites/sitesTree"];
    },
    sites() {
      return this.$store.getters["sites/fullLocationSites"];
    },
    domaines() {
      return this.$store.getters["categories/domaines"];
    }
  },
  methods: {
    postPlan() {
      this.cleanFields();
      if(this.$refs.planForm.validate()) {
        this.$store.dispatch("plans/postPlan", this.planModel);
        this.$store.dispatch("messages/announceSuccess", "Plan créé.");
        this.$store.dispatch("drawer/closeDialog");

      }
    },
    cleanFields() {
      if(this.planModel.base_image instanceof File) {
        return;
      }
      delete this.planModel.base_image;
    }
  }
}
</script>

<style scoped>

</style>