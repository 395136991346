<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="red">mdi-trash-can</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="red">
        <v-toolbar-title class="white--text">Supprimer</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-4">
        Confirmer la suppression du plan ? Tout les emplacements d'équipement seront supprimés et devront être installé
        à nouveau.
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="deletePlan" :disabled="deleting" class="white--text">Supprimer</v-btn>
        <v-progress-circular indeterminate color="red" v-if="deleting"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DeletePlanDialog',
  props: {
    plan: {}
  },
  data() {
    return {
      deleting: false,
      dialog: false,
    }
  },
  methods: {
    async deletePlan() {
      this.deleting = true;
      let result = await this.$store.dispatch("plans/deletePlan", this.plan);
      if(result === "success") {
        this.$store.dispatch("messages/announceSuccess", "Plan supprimé");
      }
      this.deleting = false;
      this.dialog = false;
    }
  }
}
</script>
